
















































































































































































































































































import moment from 'moment'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import api from '../../../../api'
import Account, { AccountType } from '../../../../store/models/Account'
import KkiapayAccount, {
  KkiapayAccountType
} from '../../../../store/models/KkiapayAccount'
import Organization from '../../../../store/models/Organization'
import Product from '../../../../store/models/Product'
//import OvaSelect from '../../components/OvaSelect.vue'
import OvaCard from '../../components/OvaCard.vue'

interface ComoponentData {
  info: string
  options: Array<Record<string, any>>
  search: string
  refundOtm: string
  disturbementOtm: string
  virtualReserveOtm: string
  vitualExternaloutgoingOtm: string
  virtualExternalincomingOtm: string
  specialAccounts: Array<Record<string, any>>
  columns: Array<Record<string, any>>
  showFiltre: boolean
  dateDebut: string
  dateFin: string
  nbFilter: number
  errorMessage: string
  isLoading: boolean
  downloadingKey: string
}

export default Vue.extend({
  data(): ComoponentData {
    return {
      isLoading: false,
      downloadingKey: '',
      nbFilter: 0,
      dateDebut: '',
      dateFin: '',
      showFiltre: false,
      specialAccounts: [],
      virtualReserveOtm: 'tous',
      vitualExternaloutgoingOtm: 'tous',
      virtualExternalincomingOtm: 'tous',
      refundOtm: '',
      disturbementOtm: '',
      search: '',
      info: '',
      errorMessage: '',
      options: [
        {
          title: 'Tous les OTM',
          data: 'tous'
        },
        {
          title: 'Moov',
          data: 'moov'
        },
        {
          title: 'MTN',
          data: 'mtn'
        }
      ],
      columns: [
        { label: 'Produit', field: 'productName' },
        { label: 'Nom du compte', field: 'name' },
        { label: 'Solde du compte', field: 'balance', currency: true },
        { label: 'OTM', field: 'otm' },
        { label: 'Action', field: 'action' }
      ]
    }
  },
  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    cardSubtitle(this: any) {
      return `Solde au ${this.$options.filters.date(
        new Date(),
        'DD/MM/YYYY à HH:mm'
      )}`
    },
    organization(this: any): Organization {
      return Organization.loggedIn() as Organization
    },
    refundBalance(this: any): number {
      const kkAccount = KkiapayAccount.query()
        .where('productId', this.getCurrentProduct.id)
        .where('type', KkiapayAccountType.REFUND)
        .first()
      if (this.refundOtm == 'moov') return kkAccount?.moovBalance ?? 0
      else if (this.refundOtm == 'mtn') return kkAccount?.mtnBalance ?? 0
      else return kkAccount?.balance ?? 0
    },
    disturbementBalance(this: any): number {
      const kkAccount = KkiapayAccount.query()
        .where('productId', this.getCurrentProduct.id)
        .where('type', KkiapayAccountType.DEFAULT)
        .first()
      if (this.disturbementOtm == 'moov') return kkAccount?.moovBalance ?? 0
      else if (this.disturbementOtm == 'mtn') return kkAccount?.mtnBalance ?? 0
      else return kkAccount?.balance ?? 0
    },
    virtualReserveBalance(this: any): number {
      let accounts
      if (this.virtualReserveOtm == 'tous')
        accounts = this.specialAccounts.filter((value: any) => {
          return value.type == AccountType.RESERVE
        })
      else
        accounts = this.specialAccounts.filter((value: any) => {
          return (
            value.type == AccountType.RESERVE &&
            value.otm == this.virtualReserveOtm
          )
        })

      const valeurInitiale = 0
      if (accounts.length != 0) {
        const result = accounts.reduce(
          (accumulateur: number, valeurCourante: any) =>
            accumulateur + valeurCourante?.balance,
          valeurInitiale
        )
        console.log('result', result)
        return !isNaN(result) ? result : 0
      }
      return 0
    },
    virtualExternaloutgoingBalance(this: any): number {
      let accounts
      if (this.vitualExternaloutgoingOtm == 'tous')
        accounts = this.specialAccounts.filter((value: Account) => {
          return value.type == AccountType.EXTERNALOUTGOING
        })
      else
        accounts = this.specialAccounts.filter((value: Account) => {
          return (
            value.type == AccountType.EXTERNALOUTGOING &&
            value.otm == this.vitualExternaloutgoingOtm
          )
        })

      const valeurInitiale = 0
      if (accounts.length != 0) {
        const result = accounts.reduce(
          (accumulateur: number, valeurCourante: any) =>
            accumulateur + valeurCourante?.balance,
          valeurInitiale
        )
        console.log('result', result)
        return !isNaN(result) ? result : 0
      }
      return 0
    },

    virtualExternalincomingBalance(this: any): number {
      let accounts
      if (this.virtualExternalincomingOtm == 'tous')
        accounts = this.specialAccounts.filter((value: Account) => {
          return value.type == AccountType.EXTERNALINCOMING
        })
      else
        accounts = this.specialAccounts.filter((value: Account) => {
          return (
            value.type == AccountType.EXTERNALINCOMING &&
            value.otm == this.virtualExternalincomingOtm
          )
        })

      const valeurInitiale = 0
      if (accounts.length != 0) {
        const result = accounts.reduce(
          (accumulateur: number, valeurCourante: any) =>
            accumulateur + valeurCourante?.balance,
          valeurInitiale
        )
        console.log('result', result)
        return !isNaN(result) ? result : 0
      }
      return 0
    },

    accounts(this: any) {
      const accounts = Account.query()
        .where('productId', this.getCurrentProduct.id)
        .with('product')
        .get()

      return accounts.map(value => {
        return {
          ...value,
          productName: value.product ? value.product.name : 'Système'
        }
      })
    },

    formatDateDebut(this: any) {
      return moment(this.dateDebut)
        .locale('fr')
        .format('Do MMMM YYYY')
    },

    formatDateFin(this: any) {
      return moment(this.dateFin)
        .locale('fr')
        .format('Do MMMM YYYY')
    },

    selectedPeriode(this: any) {
      if (this.dateDebut && this.dateFin) {
        return this.formatDateDebut + ' au ' + this.formatDateFin
      }
      return 'Périodicité'
    }
  },

  components: {
    OvaCard
    //OvaSelect
  },

  methods: {
    apply(): void {
      this.isLoading = true
      Account.api()
        .search(this.search)
        .then((response: any) => {
          this.isLoading = false
          console.log('after search', response)
        })
        .catch((error: any) => {
          this.isLoading = false
        })
      this.showFiltre = false
    },
    annuler(): void {
      this.showFiltre = false
      this.dateDebut = ''
      this.dateFin = ''
    },
    seeAccountDetails(item: any): void {
      this.errorMessage = ''
      this.downloadingKey = item.key
      api
        .get(`accounts/rcb/${item.key}`, {
          params: { from: this.dateDebut, to: this.dateFin }
        })
        .then(response => {
          this.downloadingKey = ''
          const data = response.data
          this.$router.push({
            name: 'account-preview',
            params: {
              accountKey: item.key,
              accountData: {
                ...data,
                from: this.dateDebut,
                to: this.dateFin,
                otm: item.otm,
                name: this.formatAccountNamefrench(item)
              }
            }
          })
        })
        .catch(error => {
          this.downloadingKey = ''
          this.errorMessage =
            'Un problème est survenu lors du chargement des données du compte.Veuillez réessayer'
        })
    },
    formatAccountNamefrench(account: any): string {
      const accountTypeEquiv: any = {
        disbursement: 'Décaissement',
        fees: 'Frais',
        refund: 'Remboursement',
        interest: 'Intérêt',
        reserve: 'Réserve',
        externalincoming: 'Extérieur entrant',
        externaloutgoing: 'Extérieur sortant'
      }
      let frenchName = account.name
      frenchName = frenchName.replace(/_/g, ' ')
      frenchName = frenchName.replace(
        account.type.toUpperCase(),
        accountTypeEquiv[`${account.type}`]
      )
      return frenchName
    }
  },

  async mounted() {
    Product.api().fetchAll()
    KkiapayAccount.api().fetchAll()
    Account.api().search()
    await Account.api()
      .specialAccounts(this.getCurrentProduct.id)
      .then((response: any) => {
        this.specialAccounts = response.response.data
      })
  }
})
